import React from 'react';
import moment from 'moment';

const InstagramItem = ({ data }) => {
    const instagramItem = new InstragramItemDecorator(data),
        instagramBackgroundImage = { backgroundImage: `url(${instagramItem.image})` },
        instagramTimeAgo = moment(instagramItem.created).fromNow();

    return (
        <li className="instagram-item hexagon">
            <a
                className="instagram-item__link hexagon-side hexagon-side--right"
                href={instagramItem.link}
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className="hexagon-side hexagon-side--left">
                    <div className="hexagon__content" style={instagramBackgroundImage}>
                        <div className="instagram-item__content">
                            <span className="instagram-item__caption">{instagramItem.caption}</span>
                            <span className="instagram-item__date">{instagramTimeAgo}</span>
                        </div>
                    </div>
                </div>
            </a>
        </li>
    );
};

class InstragramItemDecorator {
    constructor(data) {
        this._data = data;
        this.decorate();
    }

    decorate() {
        this.caption = this._data.caption;
        this.image = this._data.media_url;
        this.link = this._data.permalink;
        this.created = this._data.timestamp;
    }
}

export default InstagramItem;
