import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import './App.scss';
import InstagramFeed from './InstagramFeed';

smoothscroll.polyfill();

export default class App extends React.Component {
    constructor() {
        super();

        this.state = {
            showInstagramItems: false,
        };
    }

    scrollToInstagram(e) {
        e.preventDefault();

        this.setState({ showInstagramItems: true }, () => {
            const instagramEl = document.querySelector('[data-behaviour="instagram-feed"]');
            instagramEl.scrollIntoView({ behavior: 'smooth', block: 'end' });
        });
    }

    render() {
        const { showInstagramItems } = this.state;

        return (
            <div className="homepage">
                <main className="page-container">
                    <section className="page-frame">
                        <article className="page-content">
                            <header className="page-content__header">
                                <h1 className="page-title">Harry Finn</h1>
                                <h2 className="page-subtitle">
                                    <span className="page-subtitle__group">
                                        Web Developer
                                        <span className="page-subtitle__divider">/</span>
                                    </span>
                                    <span className="page-subtitle__group">
                                        Hockey Player
                                        <span className="page-subtitle__divider">/</span>
                                        Skier
                                        <span className="page-subtitle__divider">/</span>
                                        PC Gamer
                                    </span>
                                </h2>
                            </header>
                            <nav className="page-navigation">
                                <ul className="page-navigation__menu">
                                    <li className="page-navigation__item">
                                        <a
                                            className="page-navigation__link page-navigation__link--github"
                                            href="https://github.com/harryfinn"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="fa fa-github"></i>
                                        </a>
                                    </li>
                                    <li className="page-navigation__item">
                                        <a
                                            className="page-navigation__link page-navigation__link--facebook"
                                            href="https://www.facebook.com/harryfinn"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li className="page-navigation__item">
                                        <a
                                            className="page-navigation__link page-navigation__link--twitter"
                                            href="https://twitter.com/harryfinn"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li className="page-navigation__item">
                                        <a
                                            className="page-navigation__link page-navigation__link--instagram"
                                            href="https://www.instagram.com/harryfinn"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li className="page-navigation__item">
                                        <a
                                            className="page-navigation__link page-navigation__link--linkedin"
                                            href="https://www.linkedin.com/in/harryfinnuk"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <a
                                className="scroll-to js-scroll-to"
                                href="#instagram-feed"
                                onClick={(e) => this.scrollToInstagram(e)}
                            >
                                <i className="fa fa-chevron-down"></i>
                            </a>
                        </article>
                    </section>
                    {showInstagramItems && (
                        <section id="instagram-feed" className="page-frame" data-behaviour="instagram-feed">
                            <InstagramFeed />
                        </section>
                    )}
                </main>
            </div>
        );
    }
}
