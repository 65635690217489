import React from 'react';
import InstagramItem from './InstagramItem';
import './InstagramFeed.scss';

export default class InstagramFeed extends React.Component {
    constructor() {
        super();

        this.state = {
            instagram_items: [],
        };

        this.instagram = {
            access_token: process.env.REACT_APP_INSTAGRAM_TOKEN,
            user_media_endpoint: 'https://graph.instagram.com/me/media/',
            count: 5,
        };
    }

    componentWillMount() {
        const endpoint = this.userMediaEndpoint();

        fetch(endpoint)
            .then((response) => response.json())
            .then((response) => {
                if (response.data.length < 1) {
                    console.log('Instagram API error');

                    return;
                }

                this.setState({ instagram_items: response.data });
            });
    }

    userMediaEndpoint() {
        return (
            this.instagram.user_media_endpoint +
            '?fields=id,media_type,media_url,permalink,thumbnail_url,caption,timestamp&access_token=' +
            this.instagram.access_token +
            '&limit=' +
            this.instagram.count
        );
    }

    render() {
        const { instagram_items } = this.state;
        let instagram_feed_css_classes = 'instagram-feed';
        let items = instagram_items.map((item, index) => {
            return <InstagramItem data={item} key={index} />;
        });

        if (items.length > 0) {
            instagram_feed_css_classes += ' animate';
        }

        return (
            <div className={instagram_feed_css_classes}>
                <h3 className="instagram-feed__title">Recent Instagrams</h3>
                <ul className="instagram-feed__items">{items}</ul>
            </div>
        );
    }
}
